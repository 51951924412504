
.mobile {
  // Mobil ekranlar için scale ayarlaması
  @media (max-width: 768px) {
    transform: scale(1); // Mobil cihazlar için %80 ölçeklendirme
  }

  // Daha küçük mobil ekranlar (örneğin 480px ve altı)
  @media (max-width: 480px) {
    transform: scale(1); // Daha küçük mobil cihazlar için %60 ölçeklendirme
    font-size: x-small;
  }
}

.p-sm-input .p-inputtext {
  padding: 0.20rem 0.20rem !important;
  max-width: 100% !important;
}
.p-sm-button .p-button.p-button-icon-only {
  width: 2rem;
  padding: 0rem 0 !important;
}
.p-sm-button .pi {
  font-size: 0.7rem !important;
}

.p-button.p-button-sm {
  font-size: 0.475rem !important;
  padding: 0.55625rem 1.04375rem !important;
}