.ant-list .ant-list-items {
  max-height: 56vh !important;
  overflow: auto !important;
}
.ant-list-item-img {
  max-height: 141px;
  max-width: 100%;
  min-width: 220px;
}
.ant-list-vertical .ant-list-item .ant-list-item-meta {
  margin-block-end: 0px !important;
  word-wrap: break-word;
}
@media screen and (max-width: 768px) {
  .ant-list .ant-list-item .ant-list-item-action {
    margin-inline-start: 0px !important;
    margin: 0px !important;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-vertical .ant-list-item .ant-list-item-main {
    min-width: 120px !important;
  }
}
.ant-list-vertical .ant-list-item .ant-list-item-action > li {
  padding: 0px 8px !important;
}

.row-header {
  gap: 5px;
}

.my-title {
  line-height: normal;
  margin: 0px;
  color: #4a5967;
  text-align: center;
}
.ant-content-my {
  margin: 24px 14px !important;
  padding: 24px !important;
  min-height: 280px !important;
  background: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
}
@media screen and (max-width: 576px) {
  .ant-content-my {
    margin: 0px 0px !important;
    padding: 5px !important;
    border-radius: 0px !important;
  }
  .ant-list .ant-list-items {
    max-height: 68vh !important;
  }
  .row-header .ant-col {
    flex: 1;
  }
}
@media screen and (max-height: 670px) and (max-width: 320px) {
  .ant-list .ant-list-items {
    max-height: 54vh !important;
  }
}
